<template>
  <div>
    <div v-if="isLoadingFetch" style="position: fixed; z-index: 99; width: 86%">
      <b-progress
        animated
        show-progress
        variant="primary"
        :max="100"
        height="1.3rem"
        :striped="true"
        :class="'progress-bar-info'"
      >
        <b-progress-bar
          :value="100"
          :label="`Memuat data OUT Area . . .`"
          variant="primary"
        />
      </b-progress>
    </div>
    <b-row class="match-height">
      <b-col lg="6" md="6" cols="12">
        <b-card>
          <div class="body">
            <h3>
              <strong>
                <span
                  >Pengunjung dari Jalur Darat

                  <b-badge variant="light-warning">
                    OUT Area
                    <b-badge variant="warning">
                      {{ todaySal }}
                    </b-badge>
                  </b-badge>
                </span>
              </strong>
            </h3>
            <hr />
          </div>
          <YoGoodTable
            :fields="tableDaratColumns"
            :data="tableDaratData"
            :paginationOptions="{ enabled: true, perPage: 10 }"
          />
        </b-card>
      </b-col>
      <b-col lg="6" md="6" cols="12">
        <b-card>
          <div class="body">
            <h3>
              <strong>
                <span
                  >Pengunjung dari Jalur Sungai
                  <b-badge variant="light-warning">
                    OUT Area
                    <b-badge variant="warning">
                      {{ todaySal }}
                    </b-badge>
                  </b-badge>
                </span>
              </strong>
            </h3>
            <hr />
          </div>
          <YoGoodTableRiver
            :fields="tableRiverColumns"
            :data="tableRiverData"
            :paginationOptions="{ enabled: true, perPage: 10 }"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// import ComingSoon from '@/views/components/ComingSoon.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import YoGoodTable from '@/views/components/YoGoodTableOUTArea.vue'
import YoGoodTableRiver from '@/views/components/YoGoodTableOUTAreaRiver.vue'

import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
  BImg
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
export default {
  components: {
    YoGoodTable,
    YoGoodTableRiver,
    StatisticCardVertical,
    StatisticCardWithLineChart,
    // ComingSoon,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    BImg
  },
  data() {
    return {
      enterAudio: null,
      exitAudio: null,
      errorAudio: null,
      isLoadingFetch: false,
      todaySal: moment().format('DD MMM yyyy'),
      disableViewTEM: true,
      disableViewPATRA: true,
      componentKey: {
        host: 1,
        statistic: 10,
        truck: 20,
        boat: 30,
        qrcode: 40
      },
      visitorTodayA1Count: [],
      visitorTodayBCount: [],
      visitorTodayA2Count: [],
      visitorTodayCCount: [],
      visitorTodayDCount: [],
      visitorTodayRiverCount: [],
      visitorTodayALL: {},
      rtTruckTodayLoading: false,
      disableViewLimit: true,
      maxApprovement: {},
      currentApprovement: {},
      data: {
        statisticsOrder: {
          series: [
            {
              name: 'Total IN OUT',
              categories: [
                'Senin, 25 Juli',
                'Selasa',
                'Rabu',
                'Kamis',
                'Jumat',
                'Sabtu'
              ],
              data: [45, 85, 65, 45, 65, 77]
            }
          ]
        },
        statisticsProfit: {
          series: [
            {
              name: 'Total IN OUT',
              categories: [
                'Senin, 25 Juli',
                'Selasa',
                'Rabu',
                'Kamis',
                'Jumat',
                'Sabtu'
              ],
              data: [0, 20, 5, 30, 15, 45]
            }
          ]
        }
      },
      tableRiverColumns: [
        {
          label: 'Detail Kapal',
          field: 'boat_name',
          width: '1280px'
        }
      ],
      tableRiverData: [],
      tableDaratColumns: [
        { label: 'Nama / Whatsapp', field: 'full_name', width: '210px' },
        {
          label: 'Badge ( Perusahaan / Instansi )',
          field: 'badge',
          width: '360px'
        },
        { label: 'Tujuan Kunjungan', field: 'ket', width: '210px' }
      ],
      tableDaratData: [
        // {
        //   badge: '[ A.1 ] Tamu ( Visitor ) - Umum',
        //   nomor_whatsapp: '628123456789',
        //   ktp: '7409011503990011',
        //   phone: '08154571281',
        //   model: 'visitor_public',
        //   clock_in: '15 October 2024 - 21:09:54',
        //   full_name: 'Yogi Arif Widodo',
        //   alamat: 'Jl. Gerbang Dayaku No 1, Jakarta',
        //   perusahaan: 'PT Sarana Abadi Lestari',
        //   ket: 'Kunjungan untuk pertemuan bisnis'
        // },
        // {
        //   badge: '[ A.2 ] Awak 2 Truck',
        //   nomor_whatsapp: '628987654321',
        //   ktp: '6409011503990022',
        //   phone: '08154571382',
        //   model: 'visitor_private',
        //   clock_in: '15 October 2024 - 21:09:54',
        //   full_name: 'Arif Setiawan',
        //   alamat: 'Jl. Raya Industri No 5, Bekasi',
        //   perusahaan: 'PT Transportasi Jaya',
        //   ket: 'Pengiriman barang untuk proyek di Jakarta'
        // },
        // {
        //   badge: '[ B ] Identitas / Karyawan / Rutinitas',
        //   nomor_whatsapp: '628112233445',
        //   ktp: '6409011503990033',
        //   phone: '08154571483',
        //   model: 'visitor_public',
        //   clock_in: '15 October 2024 - 21:09:54',
        //   full_name: 'Dina Pratama',
        //   alamat: 'Jl. Merdeka No 45, Bandung',
        //   perusahaan: 'PT Bank Negara',
        //   ket: 'Karyawan untuk pengurusan administrasi'
        // },
        // {
        //   badge: '[ C ] Pekerja Harian',
        //   nomor_whatsapp: '628334455667',
        //   ktp: '6409011503990044',
        //   phone: '08154571584',
        //   model: 'visitor_public',
        //   clock_in: '15 October 2024 - 21:09:54',
        //   full_name: 'Budi Santoso',
        //   alamat: 'Jl. Pasar No 12, Surabaya',
        //   perusahaan: 'PT Pekerja Mandiri',
        //   ket: 'Pekerjaan harian untuk proyek konstruksi'
        // },
        // {
        //   badge: '[ D ] Pengurus Agent BBM',
        //   nomor_whatsapp: '628556677889',
        //   ktp: '7409011503990055',
        //   phone: '08154571685',
        //   model: 'visitor_public',
        //   clock_in: '15 October 2024 - 21:09:54',
        //   full_name: 'Siti Aminah',
        //   alamat: 'Jl. BBM Raya No 21, Medan',
        //   perusahaan: 'PT Pertamina',
        //   ket: 'Pengurusan distribusi bahan bakar minyak'
        // }
      ],
      timeoutDebounce: null,
      isLoadingScan: false,
      scanBuffer: '' // Temporary storage for barcode scanner input
    }
  },
  setup() {
    const { watchNotification } = useAppConfig()
    return {
      watchNotification /* FirebaseFCM */
    }
  },
  watch: {
    watchNotification() {
      console.log('anIncomingNotification ::', this.watchNotification)
      try {
        let high_light_data = this.watchNotification.data[`screen_re_fetch`]

        let will_be_refresh = ['out-area-darat']
        let go_refresh_land = will_be_refresh.some((data) =>
          high_light_data.includes(data)
        )
        console.log('vgo_refresh_land:: land', go_refresh_land)
        if (go_refresh_land) {
          this.fetchDarat()
        }
      } catch (error) {
        console.log('an error re fetch land', error)
      }

      try {
        let will_be_refresh_river = ['out-area-river']
        let go_refresh_river = will_be_refresh_river.some((data) =>
          high_light_data.includes(data)
        )
        console.log('vgo_refresh:: river', go_refresh_river)
        if (go_refresh_river) {
          this.fetchRiver()
        }
      } catch (error) {
        console.log('an error re fetch river', error)
      }
    }
  },
  async mounted() {
    console.log('Mounted: Adding keypress event listener')
    // Initialize the audio once when the component is mounted
    this.enterAudio = new Audio('/sal-enter-v2.mp3')
    this.exitAudio = new Audio('/sal-out-v2.mp3')
    this.errorAudio = new Audio('/sal-error-v1.mp3')

    document.addEventListener('keypress', this.handleKeyPress)
    await this.fetchDarat()
    await this.fetchRiver()
  },

  beforeDestroy() {
    // Remove event listener to avoid memory leaks
    console.log('BeforeDestroy: Removing keypress event listener')
    document.removeEventListener('keypress', this.handleKeyPress)
  },

  methods: {
    playSound(state) {
      this.isLoadingScan = false
      console.log('playSound : ', state)
      switch (state) {
        case 'clock_in':
          console.log('[1] playSound : ', state)
          this.enterAudio.pause() // Pause any current playback
          this.enterAudio.currentTime = 0 // Reset playback position to the start
          this.enterAudio.play().catch((error) => {
            console.error('Audio playback failed:', error)
          })
          console.log('[end] playSound : ', state)
          break
        case 'clock_out':
          console.log('[2] playSound : ', state)
          this.exitAudio.pause() // Pause any current playback
          this.exitAudio.currentTime = 0 // Reset playback position to the start
          this.exitAudio.play().catch((error) => {
            console.error('Audio playback failed:', error)
          })
          console.log('[end] playSound : ', state)
          break
        case 'error':
          console.log('[3] playSound : ', state)
          this.errorAudio.pause() // Pause any current playback
          this.errorAudio.currentTime = 0 // Reset playback position to the start
          this.errorAudio.play().catch((error) => {
            console.error('Audio playback failed:', error)
          })
          console.log('[end] playSound : ', state)
          break

        default:
          break
      }
    },
    setLoading(state) {
      this.isLoadingFetch = state
    },
    async fetchDarat() {
      this.setLoading(true)
      await useJwt.http.get('vw_visitor_out_area_land').then((response) => {
        this.setLoading(false)
        try {
          this.tableDaratData = response.data.vw_visitor_in_out_lands.rows.map(
            (item) => ({
              badge: item.badge_label, // Maps to badge
              category: item.category, // Maps to badge
              nomor_whatsapp: item.phone, // Maps to nomor_whatsapp
              ktp: item.visitor_qrcode_id, // You can adjust based on your data
              phone: item.phone, // Maps to phone
              model: item.model, // Maps to model
              clock_in: item.clock_in, // Maps to clock_in
              clock_out: item.clock_out, // Maps to clock_in
              full_name: item.full_name, // Maps to full_name
              // alamat: item.from_company, // Maps to alamat (from_company)
              perusahaan: item.from_company || '-', // Maps to perusahaan (if pemilik is empty, set to '-')
              visitor_qrcode_id: item.visitor_qrcode_id,
              ket: item.ket // Maps to ket (purpose/description)
            })
          )
        } catch (error) {}
        try {
          const { a1, a2, b, c, d, all } = response.data.analytics
          this.visitorTodayALL = all
          this.visitorTodayA1Count = a1
          this.visitorTodayA2Count = a2
          this.visitorTodayBCount = b
          this.visitorTodayCCount = c
          this.visitorTodayDCount = d
        } catch (error) {}
      })
    },
    async fetchRiver() {
      this.setLoading(true)
      await useJwt.http.get('vw_visitor_out_area_river').then((response) => {
        this.setLoading(false)
        try {
          this.tableRiverData = response.data.vw_visitor_in_out_rivers.rows
        } catch (error) {}
        try {
          const { all } = response.data.analytics
          this.visitorTodayRiverCount = all
          this.visitorTodayALL.in_out += all.in_out
          this.visitorTodayALL.in_only += all.in_only
        } catch (error) {}
      })
    },
    handleKeyPress(event) {
      const key = event.key.toLowerCase()
      const code = event.code.toLowerCase()
      const isDone = key === 'enter' || code === 'enter'
      if (isDone) {
        if (this.$can('scan-visitor', '')) {
          this.processQRCode(this.scanBuffer)
        } else {
          this.$router.push({ name: `ptsal-protections` })
        }
        this.scanBuffer = '' // Reset buffer after processing
      } else {
        // Add each keypress to the buffer (scanner sends characters as keystrokes)
        this.scanBuffer += event.key
      }
    },
    async executeQR(encrypted_key) {
      if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
      this.timeoutDebounce = setTimeout(
        () => this.startExecuteQR(encrypted_key),
        1000
      )
    },
    async startExecuteQR(encrypted_key) {
      /* hit x2 api
      1. cek qr
      2. action in or out */
      try {
        await useJwt.http
          .post('visitor/scanning/get', {
            encrypted_key: encrypted_key
          })
          .then(async (result) => {
            const visitor_qrcode = result.data.visitor_qrcode
            const visitor_public = result.data.visitor_public
            const visitor_private = result.data.visitor_private

            if (result.data.is_expired) {
              this.playSound('error')
              this.$swal({
                title: 'OOPS',
                html: `QRCode Expired`,
                timer: 2000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              return
            }
            if (result.data.is_allow_enter_area) {
              this.playSound('error')
              this.$swal({
                title: 'OOPS',
                html: `Pastikan QRCode Status masih Active`,
                timer: 2000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              return
            }

            if (visitor_qrcode.status.toLowerCase() === 'expired') {
              const message =
                'QRCode Sudah expired, Lakukan ReuseQR Terlebih dahulu untuk memasuki area PT SAL'
              this.playSound('error')
              this.$swal({
                title: 'EXPIRED',
                html: `${message}`,
                timer: 2000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            } else {
              let _userData = null
              if (visitor_public) {
                _userData = visitor_public
              }

              if (visitor_private) {
                _userData = visitor_private
              }
              let payload = {
                visitor_qrcode_id: visitor_qrcode.id,
                full_name: _userData.full_name,
                badge_id: _userData.badge_id,
                qrcode_link: visitor_qrcode.qrcode_link,
                sys_auto_renew: visitor_qrcode.sys_auto_renew,

                /* for notifications */
                token: this.$store.state.visitor.tokenNotification,
                model: visitor_qrcode.model,
                from_company: _userData.from_company
              }
              await useJwt.http
                .post('visitor/scanning/action', payload)
                .then(async (result) => {
                  console.log(result.data)
                  const {
                    action_success,
                    action,
                    full_name,
                    badge,
                    action_message
                  } = result.data
                  if (action_success === true) {
                    if (action === 'clock_in') {
                      this.playSound('clock_in')
                      this.$swal({
                        title: 'Clock IN Area',
                        html: `${full_name} Berhasil Memasuki Area PT SAL`,
                        timer: 1000,
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                      })
                    } else {
                      this.playSound('clock_out')
                      this.$swal({
                        title: 'Clock OUT Area',
                        html: `${full_name} Berhasil Keluar Area PT SAL`,
                        timer: 1000,
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                      })
                    }
                  } else {
                    this.playSound('error')
                    this.$swal({
                      title: 'OOPS',
                      html: `${full_name} ${action_message}`,
                      timer: 2000,
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary'
                      },
                      buttonsStyling: false
                    })
                  }
                })
            }
          })
          .catch((errors) => {
            this.playSound('error')
            this.$alert({
              title: `Tidak berhasil Memasuki Area PT SAL ${errors}`,
              variant: 'danger',
              icon: 'CheckIcon'
            })
          })
      } catch (error) {
        this.playSound('error')
        this.$alert({
          title: `An errors ${error}`,
          variant: 'danger',
          icon: 'CheckIcon'
        })
      }
    },
    async processQRCode() {
      await this.executeQR(this.scanBuffer)
      // Clear buffer after processing
      this.scanBuffer = ''
    },

    forceFocus() {
      const searchInput = this.$refs.searchInput.$el.querySelector('input')
      if (searchInput) {
        searchInput.focus()
      }
    }
  }
}
</script>
